import React, {useState} from 'react';
import {
  useAssignSpotifyOrderBrandMutation, // useDeferSpotifyOrderBrandAssignmentMutation,
} from '@admin/graphql-api';
import {
  Banner,
  ButtonPrimary,
  ButtonTertiary,
  DialogConfirmation,
  FormSelect,
  IconSpotifyLogo,
  Image,
  Type,
} from '@spotify-internal/encore-web';
import styled from 'styled-components';
import CompanySelect from '@core/components/CompanySelect';
import {DIALOGUE_STATES} from '@analytics/components/sales-order-review/DialogueStates';
import CloudsenseBlackImage from './cloudsense-black.svg';

const DialogRow = ({children}) => (
  <Type
    style={{display: 'flex', flexDirection: 'row', gap: 16}}
    variant='mesto'>
    {children}
  </Type>
);

export const SearchContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
`;

export const DialogColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ColumnHeader = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 8px;
`;

export const DialogDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 36px;
`;

export const ApprovalDialogue = ({
  id,
  email,
  cloudsenseAdvertiserName,
  cost,
  startAt,
  endAt,
  organizationName: initialOrganizationName,
  hasActivePixel: initialHasActivePixel,
  campaignCount: initialCampaignCount,
  organizationSlug: initialSlug,
  approvalDialogueState,
  companyId,
  setApprovalDialogueState,
}) => {
  const [company, setCompany] = useState({
    id: companyId,
    slug: initialSlug,
    hasActivePixel: initialHasActivePixel,
    name: initialOrganizationName,
    campaignCount: initialCampaignCount || '-',
  });
  const [campaignKind, setCampaignKind] = useState('attribution');

  const [approveMapping] = useAssignSpotifyOrderBrandMutation({
    variables: {
      input: {
        advertiserId: company.id,
        spotifyOrderId: id,
        campaignKind,
      },
    },
  });

  const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false);

  const handleSwitchToEditState = () => {
    setApprovalDialogueState(DIALOGUE_STATES.EDIT);
  };

  const handleApproveOnClick = async () => {
    if (approvalDialogueState === DIALOGUE_STATES.EDIT) {
      setShowUpdateConfirmation(true);
    } else {
      try {
        await approveMapping();
        setApprovalDialogueState(DIALOGUE_STATES.APPROVED);
      } catch (e) {
        console.error(e);
        setApprovalDialogueState(DIALOGUE_STATES.ERROR);
      }
    }
  };

  const handleUpdateConfirmation = async () => {
    try {
      await approveMapping();
      setApprovalDialogueState(DIALOGUE_STATES.APPROVED);
      setShowUpdateConfirmation(false);
    } catch (e) {
      console.error(e);
      setApprovalDialogueState(DIALOGUE_STATES.ERROR);
      setShowUpdateConfirmation(false);
    }
  };

  const submitOrderOnClick = () => {
    setApprovalDialogueState(DIALOGUE_STATES.SUBMIT_A_TICKET);
  };

  const handleCompanySelect = (selectedCompany) => {
    if (selectedCompany) {
      setCompany({
        id: selectedCompany.id,
        slug: selectedCompany.slug || '-',
        hasActivePixel: selectedCompany.hasActivePixel ?? '-',
        name: selectedCompany.name,
        campaignCount: selectedCompany.company?.campaignCount ?? '-',
      });
    }
  };

  return (
    <>
      {!showUpdateConfirmation && (
        <DialogConfirmation
          dialogTitle={
            approvalDialogueState === DIALOGUE_STATES.APPROVE
              ? 'Approve campaign mapping suggestion'
              : 'Edit organization information'
          }
          body={
            <div>
              <Banner style={{marginTop: 16, marginBottom: 16}}>
                {email} will be associated with the approval of this line item
              </Banner>
              <DialogDataContainer>
                <DialogColumn>
                  <ColumnHeader>
                    Cloudsense data
                    <Image
                      src={CloudsenseBlackImage}
                      alt='cloudsense'
                      style={{
                        marginLeft: 5,
                        verticalAlign: 'middle',
                      }}
                    />
                  </ColumnHeader>
                  <DialogRow>&#8226; Sales order ID: {id}</DialogRow>
                  <DialogRow>
                    &#8226; Organization name: {cloudsenseAdvertiserName}
                  </DialogRow>
                  <DialogRow> &#8226; Total spend: {cost}</DialogRow>
                  <DialogRow> &#8226; Start date: {startAt}</DialogRow>
                  <DialogRow> &#8226; End date: {endAt}</DialogRow>
                </DialogColumn>
                <DialogColumn>
                  <ColumnHeader>
                    Spotify Ad Analytics data
                    <IconSpotifyLogo
                      size='small'
                      style={{marginLeft: 5, verticalAlign: 'middle'}}
                    />
                  </ColumnHeader>
                  <DialogRow>
                    &#8226; Organization name: {company.name}
                  </DialogRow>
                  <DialogRow>
                    &#8226; Pixel active: {company.hasActivePixel}
                  </DialogRow>
                  <DialogRow>
                    &#8226; Count of campaigns: {company.campaignCount}
                  </DialogRow>
                  <DialogRow>
                    &#8226; Ad Analytics org link: {company.slug}
                  </DialogRow>
                </DialogColumn>
              </DialogDataContainer>
              <div style={{marginTop: 16}}>
                <FormSelect
                  defaultValue={campaignKind}
                  onChange={(e) =>
                    setCampaignKind((e.target as HTMLSelectElement).value)
                  }>
                  <option value='reporting'>Reporting</option>
                  <option value='attribution'>Attribution</option>
                </FormSelect>
              </div>
              {approvalDialogueState === DIALOGUE_STATES.EDIT && (
                <SearchContainer>
                  <Type variant='mestoBold' style={{marginBottom: 10}}>
                    Search for an organization in Ad Analytics
                  </Type>
                  <CompanySelect
                    overlayCss={'z-index: 100000'}
                    onSelect={handleCompanySelect}
                    selectableInputValue={false}
                  />
                  <Type
                    variant='finale'
                    semanticColor='textSubdued'
                    style={{display: 'flex', alignItems: 'center'}}>
                    Organization not found?
                    <ButtonTertiary
                      size='small'
                      condensed
                      onClick={submitOrderOnClick}
                      style={{marginLeft: 5}}>
                      <Type variant='finaleBold' semanticColor='textSubdued'>
                        {' '}
                        Submit a ticket
                      </Type>
                    </ButtonTertiary>
                  </Type>
                </SearchContainer>
              )}
            </div>
          }
          footer={
            <div>
              {approvalDialogueState === DIALOGUE_STATES.APPROVE && (
                <ButtonTertiary
                  size='small'
                  condensed
                  semanticColor='textBrightAccent'
                  onClick={handleSwitchToEditState}>
                  Edit
                </ButtonTertiary>
              )}
              {approvalDialogueState === DIALOGUE_STATES.EDIT && (
                <ButtonTertiary
                  size='small'
                  condensed
                  onClick={submitOrderOnClick}>
                  Submit a ticket
                </ButtonTertiary>
              )}
              <ButtonPrimary size='small' onClick={handleApproveOnClick}>
                {approvalDialogueState === DIALOGUE_STATES.APPROVE
                  ? 'Approve'
                  : 'Update'}
              </ButtonPrimary>
            </div>
          }
        />
      )}
      {showUpdateConfirmation && (
        <DialogConfirmation
          dialogTitle='Confirm Update'
          body='Are you sure you want to update this mapping?'
          footer={
            <div>
              <ButtonTertiary
                size='small'
                onClick={() => setShowUpdateConfirmation(false)}>
                Cancel
              </ButtonTertiary>
              <ButtonPrimary size='small' onClick={handleUpdateConfirmation}>
                Confirm
              </ButtonPrimary>
            </div>
          }
        />
      )}
    </>
  );
};
