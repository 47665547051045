import {FetchedUser} from '@core/graphql-api';
import {
  createOrgPath,
  isOrgBrand,
  isOrgBuySide,
  isOrgPodcast,
} from '@core/lib/organizations';
import useRoutes from '@core/lib/useRoutes';
import {
  FetchedBuySideOrganization,
  FetchedOrganization,
} from '@analytics/graphql-api';

export type ManageRouteProps = {
  organization: FetchedOrganization | FetchedBuySideOrganization;
  user: FetchedUser;
};

export const useManageRoutes = ({user, organization}: ManageRouteProps) => {
  return useRoutes({
    baseUrl: createOrgPath(organization, 'manage'),
    basePath: '/:part(b|a|s|p)/:slug/manage',
    routes: [
      {
        title: 'Your Pixels',
        key: 'pixels',
        isAvailable: isOrgBrand(organization) || isOrgPodcast(organization),
      },
      {
        title: 'Your Members',
        key: 'members',
      },
      {
        title: 'Your Podcasts',
        key: 'podcasts',
        isAvailable: isOrgBrand(organization) || isOrgPodcast(organization),
      },
      {
        title: 'Invite Brand',
        key: 'invite',
        isAvailable: isOrgPodcast(organization) || isOrgBuySide(organization),
      },
      /*      {
        title: 'pod.link',
        key: 'podlink',
        isAvailable:
          isKind(['PODCAST']) &&
          typeof organization?.podcastsCount === 'number' &&
          organization.podcastsCount > 0,
      }, */
      {
        title: 'Attribution Config',
        key: 'config',
        isAvailable:
          isOrgBrand(organization) &&
          organization.mode &&
          organization.mode.toLowerCase() === 'sql',
      },
      {
        title: 'Art19',
        key: 'art19',
        isAvailable: isOrgPodcast(organization),
      },
      {
        title: 'Ad Insertion Providers',
        key: 'ad-providers',
        isAvailable: isOrgPodcast(organization),
      },
      {
        title: 'Agencies',
        key: 'agencies',
        isAvailable:
          isOrgBrand(organization) &&
          'isManager' in organization &&
          !organization.isManager,
      },
      {
        title: 'Affinity (Admin)',
        key: 'affinity',
        isAvailable: isOrgBrand(organization) && user && user.isAdmin,
      },
      {
        title: 'API Keys',
        key: 'api',
        isAvailable: isOrgBrand(organization) || isOrgPodcast(organization),
      },
      {
        title: 'Usage',
        key: 'usage',
      },
    ],
  });
};
